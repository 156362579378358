/* styles.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

section {
  padding: 2rem;
}

h1 {
  color: #2c3e50;
}

h2 {
  color: #34495e;
}

a {
  color: #2980b9;
}
